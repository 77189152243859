import intl from "react-intl-universal";

export enum FamilyBonusCardStatusEnum {
  active = "active",
  blocked = "blocked",
  expired = "expired",
}

export enum FamilyBonusCardOfferRedemptionMethod {
  online,
  offline,
  selfRedemption,
}

export interface FamilyBonusCard {
  fbc_card_number: string;
  fbc_card_verification_code: string;
  valid_until?: string;
  status: FamilyBonusCardStatusEnum;
  added_at: Date;
  qr_code_data?: string;
  qr_code_format?: string;
}

export interface FamilyBonusCardOfferValidityPeriod {
  period_id: string;
  from: string;
  until: string;
  days: string;
  is_active_period: boolean;
  formatted_validity: string;
}

export interface FamilyBonusCardOffer {
  offer_id: string;
  title: string;
  description: string;
  max_usages_per_card?: number;
  max_usages_per_card_per_day?: number;
  savings_in_cents?: number;
  self_redeemable: boolean;
  online_offer_shop_url?: string;
  offer_redemption_type: string;
  validity_periods: FamilyBonusCardOfferValidityPeriod[];
  has_total_contingent: boolean;
  currently_redeemable: boolean;
}

export interface OfferRedeemabilityCheckCardResponse {
  card_number: string;
  status: RedeemabilityCheckStatus;
  card: FamilyBonusCard;
}

export interface OfferRedeemabilityCheckResponse {
  offer: RedeemabilityCheckStatus;
  cards: OfferRedeemabilityCheckCardResponse[];
}

export enum RedeemabilityCheckStatus {
  expiredCard = "expired_card",
  invalidCard = "invalid_card",
  offerNotFound = "offer_not_found",
  offerNotAvailableAnymore = "offer_not_available_anymore",
  offerNotRedeemableToday = "offer_not_redeemable_today",
  offerNotAvailable = "offer_not_available",
  totalContingentExhausted = "total_contingent_exhausted",
  maximumUsagesReached = "maximum_usages_reached",
  maximumUsagesPerDayReached = "maximum_usages_per_day_reached",
  redeemable = "redeemable",
}

export function getRedemptionMethodsForOffer({
  offer,
}: {
  offer: FamilyBonusCardOffer;
}): FamilyBonusCardOfferRedemptionMethod[] {
  if (offer.offer_redemption_type === "online") {
    return [FamilyBonusCardOfferRedemptionMethod.online];
  } else if (offer.offer_redemption_type === "offline") {
    return [FamilyBonusCardOfferRedemptionMethod.offline];
  } else if (offer.offer_redemption_type === "mixed") {
    return [
      FamilyBonusCardOfferRedemptionMethod.online,
      FamilyBonusCardOfferRedemptionMethod.offline,
    ];
  }
}

export function getSavingsSumForFamilyBonusCardOffers(
  offers: FamilyBonusCardOffer[],
): string {
  let savings = 0;
  for (const offer of offers) {
    let savingsForOffer = offer.savings_in_cents ?? 0;
    if (offer.max_usages_per_card && offer.max_usages_per_card !== -1) {
      savingsForOffer = savingsForOffer * offer.max_usages_per_card;
    } else {
      savingsForOffer = savingsForOffer * 12;
    }
    savings = savings + savingsForOffer;
  }

  return (savings / 100).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    minimumIntegerDigits: 1,
    useGrouping: true,
  });
}

export function getFormattedSavingsForOffer({
  offer,
}: {
  offer: FamilyBonusCardOffer;
}): string {
  return ((offer.savings_in_cents ?? 0) / 100).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    minimumIntegerDigits: 1,
    useGrouping: true,
  });
}

export function getFormattedRedemptionLimitsForOffer({
  offer,
}: {
  offer: FamilyBonusCardOffer;
}): string {
  if (offer.max_usages_per_card && offer.max_usages_per_card !== -1) {
    if (
      offer.max_usages_per_card_per_day &&
      offer.max_usages_per_card_per_day !== -1
    ) {
      return intl.get(
        "event_details.offer_details.max_usages_per_day_limit_with_total_limit",
        {
          max_usages_per_card: offer.max_usages_per_card,
          max_usages_per_day: offer.max_usages_per_card_per_day,
        },
      );
    } else {
      return intl.get("event_details.offer_details.max_usages_per_card_limit", {
        max_usages_per_card: offer.max_usages_per_card,
      });
    }
  } else if (
    offer.max_usages_per_card_per_day &&
    offer.max_usages_per_card_per_day !== -1
  ) {
    return intl.get("event_details.offer_details.max_usages_per_day_limit", {
      max_usages_per_day: offer.max_usages_per_card_per_day,
    });
  }
  return intl.get("event_details.offer_details.redemption_no_limit");
}
